import image1 from '../assets/images/finenetvpn.png';
import image2 from '../assets/images/finenetksa.png';
import image3 from '../assets/images/finenetproxy.jpg';
import image4 from '../assets/images/finenetmax.png';




const productData = [
    {
      id: 1,
      name: "FINE NET MAX",
      image: image4,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY", "SSH", "SlowDNS"],
      rating: 5,
      timeLeft: 27,
      reseller: "",
      appLink: "https://tinyurl.com/FineNetMaxx-Apk"
    },
    {
      id: 2,
      name: "FINE NET KSA",
      image: image2,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY", "SSH", "SLOWDNS"],
      rating: 5,
      timeLeft: 27,
      reseller: "",
      appLink: "https://tinyurl.com/FineNetKSA-apk"
    },
    {
      id: 3,
      name: "FINE NET PROXY",
      image: image3,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY", "SSH", "SlowDNS"],
      rating: 5,
      timeLeft: 24,
      reseller: "",
      appLink: "https://tinyurl.com/FineNetProxy-Apk"
    }, {
      id: 4,
      name: "FINE NET VPN",
      image: image1,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY", "SSH", "SlowDNS"],
      rating: 5,
      timeLeft: 27,
      reseller: "",
      appLink: "https://tinyurl.com/FINEnetVPN-Apk"
    }
  ];
  export default productData;
